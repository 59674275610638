import React, { useEffect } from "react";
import gql from "graphql-tag";
import { Icon, LocaleProvider, Badge, Spin, Input, DatePicker, Button, Row, Col, Select } from 'antd';
import { useMutation, useQuery } from "@apollo/react-hooks";
import fr_FR from 'antd/es/locale-provider/en_US';
import { Alert } from '../../Layout/Files';
import { ens } from '../../Layout/TemplateHeader';
import { strings } from '../../Layout/Strings';
import { GET_ALL_ZONE } from '../../../graphql/zone';
const { Option } = Select;
const { TextArea } = Input;

const ADD_USER = gql`
    mutation ADDUSER($user_name: String,$sur_name: String,$password: String $employee_number: String,$card_number: String,$position:String,$area:String,
      $email: String,$user_type: String,$lang:String,$group:String,  $zone_id: String) {   
      addUser(user_name: $user_name,sur_name: $sur_name,password:$password, employee_number: $employee_number,card_number:$card_number,email: $email,position: $position,
      area: $area,user_type: $user_type,lang:$lang,group:$group, zone_id: $zone_id)
        {
            _id,
            user_name,
            sur_name,
            email,
            password,
            employee_number,
            card_number,
            position,
            user_type,
            area,
            group,
            msg,
            status,
        }
    }
`;
export const Uploads = (props) => {
      const [user_name, setuser_name] = React.useState('');
      const [sur_name, setsur_name] = React.useState('');
      const [email, setemail] = React.useState('');
      const [password, setpassword] = React.useState('');
      const [employee_number, setemployee_number] = React.useState('');
      const [card_number, setcard_number] = React.useState('');
      const [position, setposition] = React.useState('');
      const [area, setarea] = React.useState('');
      const [user_type, setuser_type] = React.useState('');
      const [group, setGroup] = React.useState('');
      const [createUser] = useMutation(ADD_USER, {});
      const [zone, setZone] = React.useState('');
      const [zones, setZones] = React.useState([]);
      const { loading, error, data : zoneData, refetch } = useQuery(GET_ALL_ZONE, { variables: { limit: 1000, page: 1, lang: ens } });
      const [spin, setspin] = React.useState(0);
      const onChange = value => {
            setuser_type(value);
            if (value === "FIXED") {
                  setGroup("T3_GEPP_Acceso");
            } else if (value === "POOL") {
                  setGroup("T3_GEPP_SinAcceso");
            }
      };
      const onChangeZone = value => {
            setZone(value);
      };
      const oncancel = () => { props.cancelmodel(false); }
      const AddUser = () => {
            setspin(1);
            if (user_name !== '' && sur_name !== '' && email !== '' && user_type !== ''
                  && employee_number !== '' && card_number !== '' && position !== '' && area !== '' && password !== '' && zone !== '') {

                  createUser({
                        variables: {
                              user_name: user_name,
                              sur_name: sur_name,
                              email: email,
                              password: password,
                              employee_number: employee_number,
                              card_number: card_number,
                              position: position,
                              area: area,
                              user_type: user_type,
                              group: group,
                              zone_id: zone,
                              lang: ens,
                        },
                  }).then(result => {
                        setspin(0);
                        console.log(result.data);
                        props.adduser(result.data);
                  });

            } else {
                  setspin(0);
                  Alert({ msg: 'Please fill all the details', status: 'failed' });
            }
      };

      useEffect(() => {
            if (error) return `Error! ${error}`;
      }, [error])
      useEffect(() => {
            if (zoneData && zoneData.getAllZone) setZones(zoneData.getAllZone.docs)
      }, [zoneData])
      strings.setLanguage(ens);
      return (
            <div className="gutter-example">
                  <Spin tip="Loading..." className={spin === 0 ? 'd-none' : ''}  > </Spin>
                  <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text="" />
                                    <Input placeholder={strings.un} value={user_name} onChange={(e) => setuser_name(e.target.value)} />
                              </div>
                        </Col>
                        <Col span={1} style={{ paddingLeft: 12, paddingRight: 0 }}>
                              <Badge status="processing" text="" />
                        </Col>
                        <Col className="gutter-row" span={10}>
                              <div style={{ marginBottom: 16 }}>
                                    <Input placeholder={strings.sn} value={sur_name} onChange={(e) => setsur_name(e.target.value)} />
                              </div>
                        </Col>
                  </Row>
                  <Row gutter={16}>
                        <Col className="gutter-row" span={16}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text="" />
                                    <Input placeholder={strings.email} value={email} onChange={(e) => setemail(e.target.value)} />
                              </div>
                        </Col>
                        <Col className="gutter-row" span={8}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text="" style={{ marginRight: 5 }} />
                                    <Select defaultValue={strings.ut} value={user_type} style={{ width: '16.3em' }} onChange={onChange}>
                                          <Option value="">Select User type</Option>
                                          <Option value="FIXED">Fixed</Option>
                                          <Option value="POOL">Pool</Option>
                                    </Select>
                              </div>
                        </Col>
                  </Row>
                  <Row gutter={16}>
                        <Col className="gutter-row" span={11}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text="" />
                                    <Input placeholder={strings.en} value={employee_number} onChange={(e) => setemployee_number(e.target.value)} required={true} />
                              </div>
                        </Col>
                        <Col span={1} style={{ paddingLeft: 12, paddingRight: 0 }}>
                              <Badge status="processing" text="" />
                        </Col>
                        <Col className="gutter-row" span={11}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Input placeholder={strings.cn} value={card_number} onChange={(e) => setcard_number(e.target.value)} required={true} />
                              </div>
                        </Col>
                  </Row>
                  <Row gutter={16}>
                        <Col className="gutter-row" span={11}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text="" />
                                    <Input placeholder={strings.position} value={position} onChange={(e) => setposition(e.target.value)} required={true} />
                              </div>
                        </Col>
                        <Col span={1} style={{ paddingLeft: 12, paddingRight: 0 }}>
                              <Badge status="processing" text="" />
                        </Col>
                        <Col className="gutter-row" span={12}>
                              <div style={{ marginBottom: 16 }}>
                                    <TextArea rows={1} placeholder={strings.area} value={area} onChange={(e) => setarea(e.target.value)} required={true} />
                              </div>
                        </Col>
                  </Row>
                  <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text="" />
                                    <Input.Password placeholder="PASSWORD" onChange={(e) => { setpassword(e.target.value) }} />
                              </div>
                        </Col>
                        <Col className="gutter-row" span={12}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text="" style={{ marginRight: 5 }} />
                                    <Select defaultValue={strings.ut} value={zone} style={{ width: '16.3em' }} onChange={onChangeZone}>
                                          <Option value="">Select Zone</Option>
                                          {zones.map(zone => (
                                                <Option key={zone._id} value={zone._id}>{zone.name}</Option>
                                          ))}
                                    </Select>
                              </div>
                        </Col>
                  </Row>
                  <Row>
                        <Col>
                              <Button key="back" onClick={oncancel}> Cancel </Button>
                              <Button key="submit" className="float-right" type="primary" onClick={AddUser}> Add </Button>
                        </Col>
                  </Row>
            </div>
      );
};
