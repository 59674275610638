import gql from "graphql-tag";
export const GET_SLOT = gql`
{
    slots
    {
        _id
        slot_no
        slot_name
        available
        status
        data
        category
        zone {
            name
        }
        zone_id
        category_type
    }
}
`;
export const NO_SLOTS = gql`
{
    no_slots
    {
        no_slot
        acitve_slot
        disable_slot
        working_station_slot
        casual_station_slot
    }
}
`;
export const FIND_SLOT = gql`
    query findslot($_id: ID,$category:String, $zone_id: String) {
        slots(_id: $_id,category:$category, zone_id: $zone_id) {
            _id
            slot_no
            slot_name
            available,
            category,
            category_type
            description,
            url,
            url_live,
            zone_id,
            msg,
            status,
        }
    }
`;
export const FIND_VP_SLOT = gql`
    query findvpslot($category:String,$_id:ID) {
        vp_slots(category:$category,_id:$_id) {
            _id
            slot_no
            slot_name
            available,
            category,
            category_type
            description,
            url,
            url_live,
            msg,
            status,
        }
    }
`;
export const UPDATE_SLOT = gql`
    mutation UPLOADSLOT($_id:ID$slot_name: String, $slot_no: Int, $description: String ,$category: String ,$file:[Upload],,$file1:[Upload],$lang:String, $zone_id: String) {   
        updateSlot(_id:$_id,slot_name: $slot_name,slot_no: $slot_no, description: $description,file:$file,file1:$file1,category: $category,lang:$lang, zone_id: $zone_id)
        {
            _id,
            slot_name,
            slot_no,
            description,
            category,
            category_type
            photo,
            zone_id,
            msg,
            status
        }
    }
`;
export const DISABLEACTIVE_SLOT = gql`
     mutation DISABLEACTIVESLOT($_id:ID,$status:String,$lang:String) {   
        disable_active(_id:$_id,status:$status,lang:$lang)
        {
            msg,
            status
        }
    }
`;
export const DELETE_SLOT = gql`
     mutation DELETESLOT($_id:ID,$lang:String) {   
        delete_slot(_id:$_id,lang:$lang)
        {
            msg,
            status
        }
    }
`;
export const SEARCH_SLOT = gql`
    query findslots($lang: String,$sname:String,$sno:String, $zone_id: String) {
        serach_slot(lang: $lang,sname:$sname,sno:$sno, zone_id: $zone_id) {
            _id
            data
            slot_no
            slot_name
            available,
            category,
            category_type
            description,
            zone_id,
            zone{
                name
            }
            url,
            url_live,
            msg,
            status,
        }
    }
`;
export const SEARCH_SLOT_REGEX = gql`
    query SEARCHSLOTREGEX($lang: String,$sname:String,$sno:String,$limit:Int,$condition:String) {
        serach_slot_with_regex(lang: $lang,sname:$sname,sno:$sno,limit:$limit,condition:$condition) {
            _id
            data
            slot_no
            slot_name
            available,
            category,
            category_type
            description,
            url,
            url_live,
            msg,
            status,
        }
    }
`;
