
import React, { useCallback } from "react";
import { Layout, Typography, } from 'antd';
import 'antd/dist/antd.css';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import TemplateSider from '../../Layout/TemplateSider';
import TemplateHeader from '../../Layout/TemplateHeader';
import Zone_Mange from './Zone_Mange';

const { Content } = Layout;
const { Text } = Typography;

class Zone extends React.Component {
    state = {
        collapsed: false,
    };

    onToggle = (val) => {
        console.log(val);
        this.setState({
            collapsed: val,
        });
    };

    render() {

        return (
            <Layout style={{ height: '100vh' }}>
                <TemplateSider update_collapsed={this.state.collapsed} />
                <Layout>
                    <TemplateHeader toogle={this.onToggle} />
                    <Content className="content">
                        <Zone_Mange />
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default Zone;