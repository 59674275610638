import React, { useEffect } from "react";
import { Badge, Spin, Input, Button, Row, Col, Select } from 'antd';
import { useMutation } from "@apollo/react-hooks";
import { Alert } from '../../Layout/Files';
import { ens } from '../../Layout/TemplateHeader';
import { strings } from '../../Layout/Strings';
import { ADD_ZONE, UPDATE_ZONE } from '../../../graphql/zone';
const { Option } = Select;
export const HandleZone = (props) => {

    const [name, setName] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [addZone] = useMutation(ADD_ZONE, {});
    const [updateZone] = useMutation(UPDATE_ZONE, {});
    const [spin, setspin] = React.useState(0);

    const [data, setData] = React.useState('');

    useEffect(() => {
        if (props.data) {
            setData(props.data)
            setName(props.data.name);
            setStatus(props.data.status)
        }
    }, [props.data]);

    const oncancel = () => {
        setName('');
        props.cancelmodel(false);
    }
    const handleZone = () => {
        setspin(1);
        if (name !== '' && !data._id) {
            addZone({
                variables: {
                    name: name,
                    lang: ens,
                },
            }).then(result => {
                setspin(0);
                props.handleZone();
            });

        } else if (data._id) {
            updateZone({
                variables: {
                    name: name,
                    status: status,
                    id: data._id,
                    lang: ens,
                },
            }).then(result => {
                setspin(0);
                props.handleZone();
            });
        } else {
            setspin(0);
            Alert({ msg: 'Please fill all the details.', status: 'failed' });
        }
    }
    strings.setLanguage(ens);
    return (
        <div className="gutter-example">
            <Spin tip="Loading..." className={spin === 0 ? 'd-none' : ''}  > </Spin>
            <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                    <div className='d-flex' style={{ marginBottom: 16 }}>
                        <Badge status="processing" text="" />
                        <Input placeholder={strings.NAME} value={name} onChange={(e) => setName(e.target.value)} required={true} />
                    </div>
                </Col>
                {data._id && <Col className="gutter-row" span={12}>
                    <div className='d-flex' style={{ marginBottom: 16 }}>
                        <Badge status="processing" text="" />
                        <Select defaultValue={strings.st} value={status} style={{ width: '100%' }} onChange={ value => setStatus(value)}>
                            <Option value="active">Active</Option>
                            <Option value="inactive">Deactive</Option>
                        </Select>
                    </div>
                </Col>}
            </Row>
            <Row>
                <Col>
                    <Button key="back" onClick={oncancel}> Cancel </Button>
                    <Button className="float-right" key="submit" type="primary" onClick={handleZone}> Submit </Button>
                </Col>
            </Row>
        </div>
    );
};
