import gql from "graphql-tag";
export const GET_ALL_ZONE = gql`query getAllZone($lang: String = "en", $page: Int = 1, $limit: Int = 10, $s_name: String) {
    getAllZone(lang: $lang, page: $page, limit: $limit, name: $s_name) {
        docs
      totalDocs
      totalPages
      page
      prevPage
      nextPage
    }
  }`

export const ADD_ZONE = gql`mutation addZone($lang: String, $name: String) {
    addZone(lang: $lang, name: $name) {
      name
      _id
    }
  }`

export const UPDATE_ZONE = gql`mutation updateZone($lang: String, $name: String, $status: String, $id: String) {
    updateZone(lang: $lang, name: $name, status:$status, _id: $id) {
      _id
      name
      status
    }
  }`

export const DELETE_ZONE = gql`mutation deleteZone($lang: String, $id: String) {
  deleteZone(lang: $lang, _id: $id) {
    _id
    msg
    status
  }
}`