import React from "react";
import { Icon, Tooltip, Input, Button, Collapse, Select, Popconfirm, Row, Col, Table, Modal, Tag, LocaleProvider, DatePicker } from 'antd';
import { Alert } from '../../Layout/Files';
import 'antd/dist/antd.css';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import '../../../scss/User.scss';
import { ens } from '../../Layout/TemplateHeader';
import { client, URI } from "../../../graphql/Client";
import { strings } from '../../Layout/Strings';
import { GET_ALL_ZONE, DELETE_ZONE } from '../../../graphql/zone';
import { HandleZone } from './Handle_Zone';

const Panel = Collapse.Panel;
class Zone_Mange extends React.Component {

      constructor(props) {
            super(props);
            this.state = {
                  _id: '',
                  dataSource: [],
                  data: {},
                  loading: false,
                  model_visible: false,
                  editIndex: '',
                  pagination: {
                        pageSize: 10,
                        current: 1,
                        total: 0,
                        simple: true,
                  },
                  s_name: ''
            };
      }
      componentDidMount() {
            this.fetch_zones();
      }

      fetch_zones = async (limit= 10, page= 1) => {
            var variables =  { limit, page, lang: ens }
            if(this.state.s_name && this.state.s_name !== ''){
                  variables.s_name = this.state.s_name
            }
            await client.query({
                  query: GET_ALL_ZONE,
                  variables: variables,
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  this.setState({ allZone: result.data.getAllZone.docs });
                  const pagination = { ...this.state.pagination };
                  pagination.current = result.data.getAllZone.page;
                  pagination.total = result.data.getAllZone.totalDocs;
                  this.setState({ pagination, 
                    loading: false, 
                    dataSource: result.data.getAllZone.docs });
            });
      }

      // delete a booking
      DeleteBooking = async (_id) => {
            // console.log(_id);
            await client.mutate({
                  mutation: DELETE_ZONE,
                  variables: { id: _id, lang: ens },
            }).then(result => {
                  Alert(result.data.deleteZone);
                  this.fetch_zones();
            });
      };

      handleTableChange = pagination => {
            const pager = { ...pagination };
            pager.current = pagination.current;
            this.fetch_zones(pager.pageSize,  pager.current);
      };

      // add /update zone
      handleZone = async (data) => {
            this.setState({ model_visible: 0, editIndex: '' });
            this.fetch_zones();
            // Alert(data.addUser);
      }

      render() {
            strings.setLanguage(ens);
            const columns = [
                  {
                        title: <span>{strings.NAME}</span>,
                        dataIndex: '',
                        key: '',
                        width: 20,
                        align: 'center',
                        // defaultSortOrder: 'descend',
                        // sorter: (a, b) => a.user_name.length - b.user_name.length,
                        // sortDirections: ['descend', 'ascend'],
                        render: (text, record) => {
                              return <span title="NAME">{record.name}</span>;
                        }
                  },
                  {
                        title: <span>{strings.st}</span>,
                        key: 'status',
                        width: 25,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="Status">{record.status}</span>;
                        }
                  },
                  {
                        title: '...',
                        dataIndex: '...',
                        width: 5,
                        align: 'center',
                        render: (text, record) => {
                              if (this.state.dataSource.length >= 1) {
                                    return (
                                          <div>
                                                <Tooltip placement="left" title="Edit">
                                                      <Icon type="edit" tabIndex="-1" onClick={() => this.setState({ model_visible: true, editIndex: record._id })} />
                                                </Tooltip>
                                                <Tooltip placement="bottom" title={'Delete'}>
                                                      <Popconfirm title="Sure to delete?" onConfirm={() => this.DeleteBooking(record._id)}>
                                                            <Icon type="delete" />
                                                      </Popconfirm>
                                                </Tooltip>
                                          </div>
                                    );
                              }
                        }
                  },
            ];
            return (
                  <div>
                        <Modal
                              title="UPDATE ZONE"
                              style={{ top: 20 }}
                              visible={this.state.model_visible}
                              onCancel={() => this.setState({ model_visible: 0, editIndex: '' })}
                              footer={[]} >
                              <HandleZone handleZone={this.handleZone} data={this.state.dataSource.find(d => d._id == this.state.editIndex)} cancelmodel={(data) => { this.setState({ model_visible: 0, editIndex: '' }) }} />
                        </Modal>

                        <div data-toggle="tooltip" title="ADD ZONE!" data-placement="left" className="user_add_position ">
                              <Tooltip placement="left" title="Add Zone">
                                    <Icon type="user-add" className="user_add icon_size" onClick={() => this.setState({ model_visible: true,  editIndex: '' })} />
                              </Tooltip>
                        </div>
                        <Row className="mb-4">
                            <Panel header={
                                    <Row>
                                        <Col lg={24}>
                                                <Row>
                                                    <Col lg={12}>
                                                            <Input placeholder="Zone Name" onChange={(e) => { this.setState({ s_name: e.target.value }) }} />
                                                    </Col>
                                                    <Col lg={12}>
                                                            <Button className="ml-4" type="primary" onClick={() => this.fetch_zones()} >Search</Button>
                                                    </Col>
                                                </Row>
                                        </Col>
                                    </Row>
                            } key="1">
                            </Panel>
                        </Row>
                        <div id="no-more-tables">
                              <Table 
                                    dataSource={this.state.dataSource}
                                    columns={columns}
                                    bordered
                                    loading={this.state.loading}
                                    pagination={this.state.pagination}
                                    onChange={this.handleTableChange}
                              />
                        </div>
                  </div>
            );
      }
}
export default Zone_Mange;
