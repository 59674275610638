import LocalizedStrings from 'react-localization';
export const strings = new LocalizedStrings({
  en: {
    "DASHBOARD": "DASHBOARD",
    "USER": "USER",
    "ZONE": "ZONE",
    "ADMIN": "ADMIN",
    "SLOT": "WORKING STATION",
    "BOOKING": "BOOKINGS",
    "REPORT": "REPORT",
    "PENALTY": "PENALTY",
    "HOLIDAY": "HOLIDAY",
    "STATIC": "STATIC",
    "ARCHIVE":"ARCHIVE",
    "ru": "REPORTED USERS",
    "pu": "PENALISTED USERS",
    "as": "ALL SLOT",
    "ads": "ADD SLOT",
    "sd": "SLOT DETAIL",
    "nows": "NO OF WORKING STATION",
    "nocs": "NO OF CASUAL STATION",
    "noav": "NO OF VP SLOT",
    "noan": "NO OF NORMAL SLOT",
    "no_tandom_slot": "NO OF TANDEM SLOT",
    "nods": "NO OF DIRECTOR SLOT",
    "nodis": "NO OF DISABLED SLOT ",
    "noae": "NO OF EMERGENCY SLOT",
    "noas": "NO OF SLOT",
    "noaa": "NO OF ACTIVE SLOT",
    "noad": "NO OF INACTIVE SLOT",
    "pl": "PENALTY LEVEL",
    "reson": "RESON",
    "du": "DURATION",
    "apd": "ADD PENALTY DETAIL",
    "upd": "UPDATE PENALTY DETAIL",
    "au": "ADD USER",
    "ud": "USER DETAILS",
    "group": "GROUP",
    "psd": "PENALTY START DATE",
    "ped": "PENALTY END DATE",
    "vdr": "VIEW DETAIL REPORT",
    "ups": "UPDATE SLOT",
    "uls": "UPLOAD SLOT",
    "sln": "SLOT NAME",
    "slno": "SLOT NO",
    "COUNT": "COUNT",
    "rst": "REPORT STATUS",
    "bs": "BOOKING STATUS",
    "rcat": "REPORT CATEGORY",
    "DETAIL": "DETAIL",
    "date": "DATE",
    "NAME": "NAME",
    "book_rs":"BOOKING RESERVATION",
    "rep_rs": "REPORT RESERVATION",
    "rs": "RESERVATION",
    "st": "STATUS",
    "cat": "CATEGORY",
    "ll": "LEVEL",
    "START": "START",
    "END": "END",
    "ds": "DESCRIPTION",
    "ti": "TITLE",
    "adsc": "ADD STATIC CONTENT",
    "email": "EMAIL",
    "ut": "USER TYPE",
    "un": "User Name",
    "na": "Not Available",
    "sn": "Sur Name",
    "vlp": "VEHICLE LICENSE PLATE",
    "ph": "PHOTO",
    "pw": "PASSWORD",
    "en": "Employee Number",
    "cn": "Card Number",
    "area": "Area",
    "position": "Position",
    "vc": "VEHICLE COLOR",
    "vno": "VEHICLE NUMBER",
    "vm": "VEHICLE MODEL",
    "CANCEL": "CANCEL",
    "SUBMIT": "SUBMIT",
    "UPDATE": "UPDATE",
    "LOGOUT": "LOGOUT",
    "ct": "CONTENT",
    "other": "OTHER",
    "ah": "ADD HOLIDAY",
    "uh": "UPDATE HOLIDAY",
    "CHART1": "Occupation based on bookings",
    "CHART2": "Bookings by user",
    "CHART3": "Reported working spaces",
    "CHART4": "User Statistics",
    "CHART5": "Bookings And Canceled Data (Fixed slots)",
    "CHART6": "Total bookings ",
    "rd": "REPORT DETAIL",
    "pu": "PENALTY USER",
    "ui": "USER IMAGE",
    "ri": "REPORT IMAGE",
    "vd": "VECHILE DETAIL",
    "rds": "REPORT DETAIL STATUS",
    "ps": "PENALTY STATUS",
    "pc": "PENALTY COUNT",
    "an": "ADMIN NAME"
  },
  es: {
    "DASHBOARD": "TABLERO",
    "an": "NOMBRE ADMINISTRADOR",
    "USER": "USUARIOS",
    "ZONE": "ZONE",
    "ADMIN": "ADMINISTRADORES",
    "SLOT": "ESTACIÓN DE TRABAJO",
    "BOOKING": "RESERVAS",
    "REPORT": "REPORTES",
    "PENALTY": "PENA",
    "HOLIDAY": "DÍAS FERIADOS",
    "STATIC": "TEXTOS",
    "ARCHIVE":"ARCHIVO",
    "sd": "DETALLE DE LUGAR",
    "ru": "REPORTAR USUARIO",
    "pu": "PENA USUARIO",
    "as": "TODOS LOS LUGARES",
    "ads": "AGREGAR RANURA",    
    "nows": "ESTACIÓNES DE TRABAJO",
    "nocs": "ESTACIÓNES CASUALES",
    "noav": "NO DE VP SLOT",
    "noan": " NO DE RANURA NORMAL",
    "nods": "NO DE LA RANURA DEL DIRECTOR",
    "nodis": "NO DE RANURA DESACTIVADA",
    "noae": " NO DE RANURA DE EMERGENCIA",
    "noas": "NO. DE LUGARES",
    "noaa": "LUGARES ACTIVOS",
    "noad": "LUGARES INACTIVOS",
    "pl": "NIVEL DE PENALIZACIÓN",
    "reson": "RESON",
    "vno": "NÚMERO DE VEHÍCULO",
    "du": "DURATION",
    "apd": "AGREGAR DETALLE DE PENALIZACIÓN",
    "upd": " ACTUALIZAR DETALLE DE PENALIZACIÓN",
    "au": "AGREGAR USUARIO",
    "ud": "DETALLES DE USUARIO",
    "group": "GRUPO",
    "psd": " FECHA DE INICIO DE PENALIZACIÓN",
    "ped": " FECHA DE FINALIZACIÓN DE LA PENA",
    "vdr": "VER INFORME DETALLADO",
    "ups": " Ranura de actualización",
    "uls": "CARGAR RANURA",
    "sln": "Lugar",
    // "sln": "Nombre de la ranura",
    "slno": "Número",
    "COUNT": "CANTIDAD",
    "rst": "REPORT STATUS",
    "bs": "ESTADO DE LA RESERVACIÓN",
    "rcat": "CATEGORÍA DE INFORME",
    "DETAIL": "DETALLE",
    "date": "FECHA",
    "NAME": "NOMBRE",
    "book_rs": "RESERVA DE RESERVA",
    "rep_rs": "RESERVA DE INFORMES",
    "rs": "RESERVA",
    "st": "ESTADO",
    "cat": "CATEGORÍA",
    "ll": "NIVEL",
    "START": "COMIENZO",
    "END": "FINAL",
    "ds": "DESCRIPTION",
    "ti": "TÍTULO",
    "adsc": " AGREGAR CONTENIDO ESTÁTICO",
    "email": "CORREO ELECTRÓNICO",
    "ut": "TIPO DE USUARIO",
    "un": "NOMBRE DE USUARIO",
    "na": "NO DISPONIBLE",
    "sn": "APELLIDO",
    "vlp": "PLACA DE LICENCIA DE VEHÍCULO",
    "ph": "FOTO",
    "pw": "CONTRASEÑA",
    "en": "NÚMERO DE EMPLEADO",
    "cn": "NÚMERO DE TARJETA",
    "area": "ÁREA",
    "position": "POSICIÓN",
    "vc": "COLOR DE VEHICULO",
    "vm": "MODELO DE VEHÍCULO",
    "CANCEL": "CANCELAR",
    "SUBMIT": "ENVIAR",
    "UPDATE": "ACTUALIZAR",
    "LOGOUT": "CERRAR SESIÓN",
    "ct": "CONTENIDO",
    "other": "OTRA",
    "ah": "AGREGAR VACACIONES",
    "uh": "ACTUALIZAR VACACIONES",
    "CHART1": "Ocupación basada en reservas",
    "CHART2": "Reservas por usuario",
    "CHART3": "Espacios de trabajo informados",
    "CHART4": "Estadísticas de usuario",
    "CHART5": "Bookings And Canceled Data (Fixed slots)",
    "CHART6": "Total bookings",
    "rd": "DETALLE DEL INFORME ",
    "pu": "USUARIO DE PENALIZACIÓN ",
    "ui": "IMAGEN DEL USUARIO ",
    "ri": "REPORTAR IMAGEN ",
    "vd": "DETALLES DEL VEHÍCULO ",
    "rds": " INFORME DETALLE ESTADO",
    "ps": "ESTADO DE PENALIZACIÓN",
    "pc": "CUENTA DE PENALIZACIÓN"
  }
});